:root {
    --black: #010221;
    --skobeloff: #0a7373;
    --marigold: #edaa25;
    --mahogany: #c43302;
    --lgreen: #b7bf99;
    --white: #f7f8fa;
    --font-size: 1.3rem;
    --mono: 'Oxygen mono', monospace;
    --sans: Oxygen, sans-serif;
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

/* generic styles for the page */

body {
    padding: 0;
    margin: 0;
    font-family: var(--sans);
    background-color: var(--black);
    color: var(--white);
    font-size: var(--font-size);
}

h1,
h2,
h3 {
    margin: 0;
}

a {
    color: var(--mahogany);
}

a:hover {
    color: var(--mahogany);
    text-decoration: none;
}

img {
    width: 100%;
}

div.content {
    margin: 10px 0;
    background-color: var(--skobeloff);
    display: flex;
    flex-flow: wrap column;
    gap: 10px;
}

/* background color divs */

.section-plum {
    background-color: var(--marigold);
}

.section-blue {
    background-color: var(--skobeloff);
}

.gradient {
    background: linear-gradient(
            90deg,
            var(--lgreen) 0%,
            var(--marigold) 35%,
            var(--mahogany) 100%
    );
    height: 2px;
}

.stack {
    color: var(--lgreen);
    padding: 0.3rem;
}

#intro {
    padding: 4rem 1rem 10rem 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

#intro p {
    font-size: 1rem;
    line-height: 1.5;
}

/*articles */
#articles-headers {
    text-align: center;
    font-size: 3rem;
    padding: 1rem;
}

#articles h1 {
    font-size: 3rem;
    text-align: center;
}

#articles div {
    text-align: left;
    padding: 1rem 23rem;
}

#articles p {
    font-size: 2rem;
    margin-bottom: 0;
}

#articles li {
    font-size: 1.5rem;
}

/* contact section */

#contact {
    width: 355px;
    text-align: center;
    margin: 0 auto;
    padding: 4rem 0;
}

#contact p:last-child {
    margin-top: 3rem;
}

/* navigation */

nav {
    font-family: var(--mono);
    font-size: 80%;
    padding: 4rem 1rem;
}

nav h1 a {
    font-family: var(--sans);
}

nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

nav li:first-child {
    flex-basis: 100%;
    text-align: center;
}

nav [class*='fa-'] {
    font-size: 150%;
    color: var(--lgreen);
}

nav h1 [class*='fa-'] {
    font-size: 100%;
    color: var(--lgreen);
}

nav a {
    color: white;
    text-decoration: none;
    display: block;
}

nav a:hover,
nav [class*='fa-']:hover {
    color: var(--mahogany);
}

.button {
    background-color: var(--mahogany);
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
    text-decoration: none;
    font-family: var(--mono);
}

.button:hover {
    color: white;
    background-color: var(--mahogany);
}


/* footer section */

footer {
    text-align: center;
    padding: 2rem 0;
}

footer ul {
    list-style-type: none;
    padding: 0;
    margin: 2rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 3rem;
    font-size: 3rem;
}

@media (min-width: 800px) {
    /*article {*/
    /*    display: grid;*/
    /*    grid-template-columns: repeat(10, 1fr);*/
    /*}*/
}

@media (min-width: 850px) {
    nav {
        max-width: 1200px;
        margin: 0 auto;
    }

    nav li:first-child {
        flex-basis: auto;
        text-align: left;
        margin-right: auto;
    }

    #contact {
        width: 400px;
    }
}
